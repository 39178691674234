.C-toolpit-custom-width.\--wrap:before {
  width: 550px !important;
}

.ess-font-bold {
  font-weight: 900;
}

.ess-text-center {
  text-align: center;
}

.ess-reg-edit {
  font-size: 12px;
}

.ess-content-hide {
  display: none;
}

#hubbub_email_id.C-GridItem {
  padding-bottom: 0px;
}

#hubbub_portal ul {
  list-style: disc;
}

.ess-content-bold {
  font-weight: bold;
}

#registration_form .MuiAccordion-root
{
  z-index: 1;
}

.C-TableHeader.table-header-font {
  font-weight: bold;
  color: #000;
  font-size: 14px;
}
.C-TableData.table-data-font {
  font-size: 14px;
}

.MuiAccordionDetails-root {
  display: block !important;
  padding: 8px 16px 16px;
}