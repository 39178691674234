/*MaterialTable styles*/

.C-GridItem .MuiTableCell-head {
  color: #888;
}

.C-GridItem .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #888;
}

.C-GridItem .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #888;
}

.C-GridItem .MuiTableRow-head {
  height: auto;
  line-height: 1.25;
  margin-top: -1px;
  word-break: break-word;
}

.C-GridItem .MuiTableCell-root {
  padding: 12px 12px 12px 0;
  color: #333;
  text-align: left;
}

.C-GridItem .MuiTable-root {
  font-size: 12px;
  text-align: left;
  color: #333;
  font-weight: 400;
  line-height: 15px;
  height: 56px;
  min-height: 36px;
  font-family: Helvetica;
}
