.selected-table-record {
  background: '#d6d6d6';
}

.selected-table-record .C-FormRadio__Option__Selector {
  border: 6px solid #366cd9;
}

.business-contacts-list {
  list-style-type: none;
  padding-inline-start: 0px;
}
